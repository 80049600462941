/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import NavLink from 'components/shared/NavLink'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

interface SubmenuProps {
  // eslint-disable-next-line
  fields: any
}

const Item = styled(motion.li)`
  list-style: none;

  & > a {
    color: ${(props) => props.theme.color.primary} !important;

    &[aria-current] {
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;

    & > a {
      font-size: 16px;
      line-height: 16px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.color.light};
    }
  }
`

const StyledSubmenu = styled(motion.div)`
  overflow: hidden;
  background: ${({ theme }) => theme.color.light};

  @media screen and (min-width: 992px) {
    position: absolute;
    top: 25px;
    overflow: hidden;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    left: 0;
    min-width: 110px;
  }

  @media screen and (max-width: 991px) {
    position: absolute;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    padding-top: unset;
    width: 60%;

    & > div {
      padding: 2px 5px 2px 5px;
    }
  }
`

const Menu = styled.div`
  padding: 1rem;
`

const Submenu: React.FC<SubmenuProps> = ({ fields }) => (
  <StyledSubmenu
    initial={{ height: 0 }}
    animate={{ height: 'fit-content' }}
    exit={{ height: 0 }}
    className="d-flex"
  >
    <Menu className="position-relative d-flex">
      <div className="">
        {fields !== null &&
          fields?.map((f: any, index: number) => (
            <div className="d-flex" key={index}>
              <Item>
                <NavLink to={f?.link?.url || '/'} key={index}>
                  {f?.link?.title}
                </NavLink>
              </Item>
            </div>
          ))}
      </div>
    </Menu>
  </StyledSubmenu>
)

export default Submenu
