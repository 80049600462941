import React from 'react'

// Components
import Menu from 'components/layout/Header/Menu'

// Third Party
import styled from 'styled-components'

// Images
import Logo from 'img/logo.inline.svg'
import NavLink from 'components/shared/NavLink'
import BottomMenuMobile from './BottomMenuMobile'

const Brand = styled(NavLink)`
  position: relative;
  z-index: 100;
  & > svg {
    height: 45px;
    width: 159px;
    @media (max-width: 575px) {
      height: 35px;
      width: 129px;
    }
  }
`

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.light};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;

  & ul {
    padding-top: 150px;
    padding-bottom: 150px;
    overflow-y: scroll;
    padding-left: 0;
    list-style: none;
    margin: 0;
    width: 70%;

    & li {
      @media (min-width: 576px) {
        margin-top: 0.75rem;

      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.dark};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.dark};
  height: 2px;
  width: 50%;
  margin: 5px auto;
`

interface MobileProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
  setIsScrollable: Function
}

const Mobile: React.FC<MobileProps> = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <div className="d-flex d-lg-none justify-content-between align-items-center w-100">
      <Brand to="/">
        <Logo />
      </Brand>
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
          </NavButtonClose>
          <ul>
            <Menu fields={fields} />
            <BottomMenuMobile fields={fields} />
          </ul>
        </MenuWrapper>
      )}
    </div>
  )
}

export default Mobile
