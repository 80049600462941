import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

// Images
import Chevron from 'img/chevron.inline.svg'
import NavLink from 'components/shared/NavLink'

const Anchor = styled(motion.div)`
  display: flex;
  align-items: center;
`

const ChevronWrapper = styled(motion.span)<{ isLight: boolean }>`
  & svg path {
    ${(props) =>
      props.isLight &&
      css`
        fill: #fff;
      `}
  }
`

interface AnchorPrimaryProps {
  href: string
  className?: string
  target?: string
  isLight?: boolean
}

const AnchorPrimary: React.FC<AnchorPrimaryProps> = ({
  children,
  href,
  className = '',
  target,
  isLight,
}) => {
  const [hovered, setHovered] = useState<boolean>(false)

  return (
    <NavLink to={href}>
      <Anchor
        className={className}
        target={target}
        onHoverStart={() => setHovered(true)}
        onHoverEnd={() => setHovered(false)}
      >
        <ChevronWrapper
          animate={hovered ? { x: 10 } : { x: 0 }}
          isLight={isLight}
        >
          <Chevron className="me-2 me-sm-3" />
        </ChevronWrapper>
        {children}
      </Anchor>
    </NavLink>
  )
}

export default AnchorPrimary
