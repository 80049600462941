/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'

// Components
import NavLink from 'components/shared/NavLink'

// Third Party
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Images
import Chevron from 'img/chevron.inline.svg'
import ParseContent from 'components/shared/ParseContent'

interface SubmenuProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.Maybe<GatsbyTypes.WpComponent_Header_MenuHeader_menu_Submenu>
}

const Item = styled(motion.li)`
  list-style: none;
  margin-bottom: 20px;

  & > a {
    color: ${(props) => props.theme.color.primary} !important;

    &[aria-current] {
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;

    & > a {
      font-size: 16px;
      line-height: 16px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.color.light};
    }
  }
`

const StyledSubmenu = styled(motion.div)`
  overflow: hidden;
  background: ${({ theme }) => theme.color.light};

  @media screen and (min-width: 992px) {
    position: absolute;
    top: 110px;
    overflow: hidden;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    left: 0;
  }

  @media screen and (max-width: 991px) {
    position: absolute;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    padding-top: unset;
    width: 60%;

    & > div {
      padding: 2px 5px 2px 10px;
    }
  }
`

const Menu = styled.div`
  padding: 20px 30px;
`

const Sidebar = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  padding: 20px 15px;

  & a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 20px;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 430px;
  height: 270px;
`

const SidebarWrapper = styled.div`
  @media (max-width: 1399px) {
    width: 100%;
  }
`

const Image = styled(Plaatjie)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  right: 0;
`

const Submenu: React.FC<SubmenuProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const imageAnimations = {
    collapsed: {
      height: 0,
      opacity: 0,
    },
    open: {
      height: '100%',
      opacity: 1,
    },
  }

  return (
    <StyledSubmenu
      initial={{ height: 0 }}
      animate={{ height: 'fit-content' }}
      exit={{ height: 0 }}
      className="d-flex"
    >
      <Menu className="position-relative d-flex">
        <div className="mt-3">
          {fields?.menu !== null &&
            fields?.menu?.map((f, index) => (
              <div className="d-flex" key={index}>
                <Item whileHover={() => setCurrentIndex(index)}>
                  <NavLink to={f?.link?.url || '/'} key={index}>
                    <Chevron className="me-2" />
                    {f?.link?.title}
                  </NavLink>
                </Item>
              </div>
            ))}
        </div>
        <div className="d-lg-flex d-none">
          <AnimatePresence>
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={imageAnimations}
            >
              <ImageWrapper className="ms-5">
                <Image image={fields?.menu![currentIndex]?.image} alt="" />
              </ImageWrapper>
            </motion.div>
          </AnimatePresence>
        </div>
      </Menu>
      <SidebarWrapper className="d-lg-block d-xl-flex d-none">
        <Sidebar className="w-100">
          <ParseContent content={fields?.description} />
        </Sidebar>
      </SidebarWrapper>
    </StyledSubmenu>
  )
}

export default Submenu
