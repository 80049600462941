import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'
import { motion } from 'framer-motion'

// Images
import ChevronRight from 'img/chevron.inline.svg'

export const ButtonSecondaryCSS = css`
  background-color: ${(props) => props.theme.color.primary};
  display: inline-flex;
  align-items: center;

  & > a,
  & > button {
    color: ${(props) => props.theme.color.light} !important;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding: 10px 19px;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none !important;
    @media (min-width: 1200px) {
      font-size: 16px;
    }
    @media (max-width: 1199px) {
      font-size: 14px;
    }
  }

  & svg path {
    fill: #fff;
  }
`

const StyledButton = styled(motion.span)`
  ${ButtonSecondaryCSS};
`

interface ButtonSecondaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
  target?: string
}

const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}) => {
  const [hovered, setHovered] = useState<boolean>(false)

  return (
    <StyledButton
      className={className}
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
    >
      {isCustom ? (
        children
      ) : (
        <ButtonShell
          to={to}
          isAnchor={isAnchor}
          target={target}
          className="d-flex align-items-center"
        >
          {children}
          <motion.span
            animate={hovered ? { x: 10 } : { x: 5 }}
            className="d-flex"
          >
            <ChevronRight />
          </motion.span>
        </ButtonShell>
      )}
    </StyledButton>
  )
}

export default ButtonSecondary
