import React from 'react'

// Components
import Topmenu from 'components/layout/Header/Topmenu'
import Menu from 'components/layout/Header/Menu'

// Shared
import NavLink from 'components/shared/NavLink'

// Third Party
import styled from 'styled-components'

// Images
import Logo from 'img/logo.inline.svg'

const DesktopWrapper = styled.div`
  & ul {
    list-style: none;
    margin-bottom: 0;
  }
`

const StyledTopmenu = styled.ul`
  height: 50px;
`

const StyledMenu = styled.ul`
  background: ${({ theme }) => theme.color.light};
  flex: 1;
  align-items: center;
  height: 100px;
`

const Brand = styled(NavLink)`
  & > svg {
    &:hover {
      opacity: 0.75;
    }
    @media (min-width: 1400px) {
      height: 45px;
      width: 159px;
    }
    @media (max-width: 1399px) {
      height: 35px;
      width: 139px;
    }
  }
`

interface DesktopProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
}

const Desktop: React.FC<DesktopProps> = ({ fields }) => (
  <DesktopWrapper className="d-none d-lg-block w-100">
    <StyledTopmenu className="d-flex align-items-center">
      <div className="container d-flex align-items-center h-100">
        <Topmenu fields={fields} />
      </div>
    </StyledTopmenu>
    <StyledMenu>
      <div className="container d-flex align-items-center justify-content-between h-100">
        <Brand to="/">
          <Logo />
        </Brand>
        <div className="d-flex align-items-center position-relative">
          <Menu fields={fields} />
        </div>
      </div>
    </StyledMenu>
  </DesktopWrapper>
)

export default Desktop
