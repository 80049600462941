import { useContext } from 'react'

import { Context } from 'components/layout/Wrapper'

const useWrapper = () => {
  const context = useContext(Context)

  return context
}

export default useWrapper
