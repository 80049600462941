/* eslint-disable react/no-array-index-key */
import React from 'react'
import StarRatings from 'react-star-ratings'

// Components
import NavLink from 'components/shared/NavLink'
import SmallSubmenu from 'components/layout/Header/SmallSubmenu'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import { motion, AnimatePresence } from 'framer-motion'

// Hooks
import useWrapper from 'hooks/useWrapper'

// Images
import Search from 'img/search.inline.svg'
import Chevron from 'img/white-chevron.inline.svg'

const Item = styled.li`
  position: relative;

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  & div > a {
    color: ${(props) => props.theme.color.dark};
    text-transform: uppercase;

    &[aria-current] {
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;

    & div > a {
      font-size: 16px;
      line-height: 22px;
      font-weight: ${({ theme }) => theme.font.weight.light};
    }
  }
`

const Socials = styled.div`
  & a {
    font-size: 0;
  }
`

const Icon = styled(Plaatjie)`
  width: 18px;
  height: 18px;
`

const Phone = styled.div`
  & a {
    color: ${({ theme }) => theme.color.light} !important;
  }
`

const SearchLink = styled(NavLink)`
  position: relative;
  top: 14px;
  font-size: 22px;
  line-height: 22px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  & svg path {
    fill: #000000;
  }
  @media (max-width: 991px) {
    font-size: 14px;
  }
`

const BottomSection = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.color.light};
`

const BottomInner = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
`

const SvgWrapper = styled.div<{ open: boolean }>`
  margin-left: 10px;
  transform: rotate(90deg);

  & > svg {
    transition: all 0.3s ease;

    ${({ open }) =>
      open
        ? css`
            transform: rotate(180deg);
          `
        : css`
            transform: rotate(0deg);
          `};
  }

  @media (max-width: 991px) {
    & > svg {
      path {
        fill: #18263f;
      }
    }
  }
`

interface BottomMenuMobileProps {
  fields: GatsbyTypes.WpComponent
}

const BottomMenuMobile: React.FC<BottomMenuMobileProps> = ({ fields }) => {
  const { link, menu, socials, phone }: any = fields.header?.topmenuHeader
  const [open, setOpen] = React.useState<number>(-1)

  const wrapper = useWrapper()

  const { reviewMeta }: any = wrapper

  const starCount = Number(reviewMeta?.average / 2) || 10

  return (
    <>
      {menu.map((l: any, index: number) => (
        <Item key={index}>
          <div>
            <NavLink to={l?.link?.url || '/'}>{l?.link?.title}</NavLink>
            {l?.submenu && (
              <SvgWrapper
                onClick={
                  open === index ? () => setOpen(-1) : () => setOpen(index)
                }
                open={open === index}
              >
                <Chevron />
              </SvgWrapper>
            )}
          </div>
          {l?.submenu && (
            <AnimatePresence>
              {open === index && <SmallSubmenu fields={l.submenu} />}
            </AnimatePresence>
          )}
        </Item>
      ))}
      <SearchLink to="/zoeken">
        <span>ZOEKEN</span>
        <Search className="ms-2" />
      </SearchLink>
      <BottomSection>
        <NavLink
          to={link.url}
          className="d-flex flex-column align-items-center "
        >
          <StarRatings
            rating={starCount}
            starDimension="20px"
            starSpacing="2px"
            starRatedColor="rgb(255, 206, 10)"
            starEmptyColor="rgb(24, 38, 63)"
            isAggregateRating
          />
          <span>
            {`${reviewMeta?.average}/10 uit ${reviewMeta?.count} beoordelingen`}
          </span>
        </NavLink>

        <BottomInner className="d-flex justify-content-between align-items-center p-2">
          <div>
            <Phone>
              <a
                href={`tel:${phone.phonenumber}`}
                className="d-flex align-items-center"
              >
                <Icon
                  image={phone.icon}
                  alt={phone.icon.altText}
                  className="me-2"
                />
                {phone.phonenumber}
              </a>
            </Phone>
          </div>
          <Socials>
            {socials.map((s: any, index: number) => (
              <a
                key={index}
                href={s.link.url}
                target="_blank"
                rel="noreferrer"
                className="me-4"
              >
                <Icon image={s.icon} alt={s.icon.altText} />
                {s.link.title}
              </a>
            ))}
          </Socials>
        </BottomInner>
      </BottomSection>
    </>
  )
}

export default BottomMenuMobile
