/* eslint-disable react/no-array-index-key */
import React from 'react'
import StarRatings from 'react-star-ratings'

// Components
import NavLink from 'components/shared/NavLink'

// Third Party
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Images
import Search from 'img/search.inline.svg'
import Chevron from 'img/white-chevron.inline.svg'

// Hooks
import useWrapper from 'hooks/useWrapper'
import Loading from 'components/elements/Misc/Loading'
import SmallSubmenu from './SmallSubmenu'

const Item = styled(motion.li)`
  position: relative;

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @media (min-width: 1200px) {
      margin-left: 48px;
    }
    @media (max-width: 1199px) {
      margin-left: 20px;
    }
  }

  & div > a {
    color: ${(props) => props.theme.color.light};

    &[aria-current] {
    }

    &:hover {
      opacity: 0.7;
    }

    @media (max-width: 1199px) {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;

    & div > a {
      font-size: 20px;
      line-height: 20px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
`

const Socials = styled.div`
  margin-left: 48px;
  & a {
    font-size: 0;
    opacity: 0.75;
    &:hover {
      opacity: 1;
    }
  }
`

const Icon = styled(Plaatjie)`
  width: 15px;
  height: 15px;
`

const Phone = styled.div`
  margin-left: 40px;

  & a {
    color: ${({ theme }) => theme.color.light} !important;
    opacity: 0.75;
    &:hover {
      text-decoration: underline;
      opacity: 1;
    }
  }
`

const SearchLink = styled(NavLink)`
  margin-left: 48px;
  opacity: 0.75;
  &:hover {
    opacity: 1;
  }
`

const LeftLink = styled(NavLink)`
  color: ${({ theme }) => theme.color.light} !important;
  opacity: 0.8;
  &:hover {
    text-decoration: underline;
  }
  & .hide-on-small-width {
    @media (min-width: 1400px) {
      display: inline;
    }
    @media (max-width: 1399px) {
      display: none;
    }
    @media (max-width: 991px) {
      display: inline;
    }
  }
`

const StarsWrapper = styled.div`
  position: relative;
  top: -4px;
`

const SvgWrapper = styled.div<{ open: boolean }>`
  margin-left: 10px;
  transform: rotate(90deg);

  & > svg {
    transition: all 0.3s ease;

    ${({ open }) =>
    open
      ? css`
            transform: rotate(180deg);
          `
      : css`
            transform: rotate(0deg);
          `};
  }

  @media (max-width: 991px) {
    & > svg {
      path {
        fill: #18263f;
      }
    }
  }
`

interface TopmenuProps {
  fields: GatsbyTypes.WpComponent
}

const Topmenu: React.FC<TopmenuProps> = ({ fields }) => {
  const { link, menu, socials, phone }: any = fields.header?.topmenuHeader
  const [open, setOpen] = React.useState<number>(-1)

  const wrapper = useWrapper()

  const { reviewMeta }: any = wrapper

  const starCount = Number(reviewMeta?.average / 2) || 10

  return (
    <div className="d-flex justify-content-between w-100">
      <div className="position-relative">
        {reviewMeta?.loading && (
          <div className="d-flex align-items-center">
            <Loading />
            <small className="ms-5 text-white">Reviews aan het laden</small>
          </div>
        )}

        {!reviewMeta?.loading && typeof reviewMeta !== `undefined` && (
          <LeftLink to={link.url} className="d-flex align-items-center">
            <StarsWrapper className="d-flex align-items-center">
              <StarRatings
                rating={starCount}
                starDimension="20px"
                starSpacing="2px"
                starRatedColor="rgb(255, 206, 10)"
                starEmptyColor="rgb(24, 38, 63)"
                isAggregateRating
              />
            </StarsWrapper>
            <span className="ms-2">{`${reviewMeta?.average}/10`}</span>
            <span className="hide-on-small-width">
              &nbsp;{`uit ${reviewMeta?.count} beoordelingen`}
            </span>
          </LeftLink>
        )}
      </div>
      <div className="d-flex">
        {menu.map((l: any, index: number) => (
          <Item
            onHoverStart={() => setOpen(index)}
            onHoverEnd={() => setOpen(-1)}
            key={index}
          >
            <div>
              <NavLink to={l?.link?.url || '/'}>{l?.link?.title}</NavLink>
              {l?.submenu && (
                <SvgWrapper
                  onClick={
                    open === index ? () => setOpen(-1) : () => setOpen(index)
                  }
                  open={open === index}
                >
                  <Chevron />
                </SvgWrapper>
              )}
            </div>
            {l?.submenu && (
              <AnimatePresence>
                {open === index && <SmallSubmenu fields={l.submenu} />}
              </AnimatePresence>
            )}
          </Item>
        ))}
        <Socials className="d-flex align-items-center">
          {socials.map((s: any, index: number) => (
            <a
              key={index}
              href={s.link.url}
              target="_blank"
              rel="noreferrer"
              className="me-2"
            >
              <Icon
                image={s.icon}
                alt={s.icon.altText}
                style={
                  s.link.url.includes('instagram')
                    ? { marginTop: 2 }
                    : { marginTop: 0 }
                }
              />
              {s.link.title}
            </a>
          ))}
        </Socials>
        <Phone>
          <a
            href={`tel:${phone.phonenumber}`}
            className="d-flex align-items-center"
          >
            <Icon
              image={phone.icon}
              alt={phone.icon.altText}
              className="me-2"
            />
            {phone.phonenumber}
          </a>
        </Phone>
        <SearchLink to="/zoeken">
          <Search />
        </SearchLink>
      </div>
    </div>
  )
}

export default Topmenu
