/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Mobile from 'components/layout/Header/Mobile'
import Desktop from 'components/layout/Header/Desktop'

// Third Party
import styled, { css } from 'styled-components'

const StyledHeader = styled.nav<{ isSticky: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;

  ${(props) =>
    props.isSticky &&
    css`
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    `}

  @media (max-width: 991px) {
    background-color: ${(props) => props.theme.color.light};
    height: 75px;
  }
  @media (min-width: 992px) {
    background-color: ${(props) => props.theme.color.primary};
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
}) => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      wpComponent(databaseId: { eq: 97 }) {
        header {
          topmenuHeader {
            link {
              title
              url
            }
            menu {
              link {
                title
                url
              }
              submenu {
                link {
                  title
                  url
                }
              }
            }
            socials {
              icon {
                localFile {
                  publicURL
                }
              }
              link {
                title
                url
              }
            }
            phone {
              icon {
                localFile {
                  publicURL
                }
              }
              phonenumber
            }
          }
          menuHeader {
            menu {
              link {
                title
                url
              }
              submenu {
                menu {
                  link {
                    title
                    url
                  }
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 100, width: 600)
                      }
                    }
                  }
                }
                description
              }
            }
            cta {
              title
              url
            }
          }
        }
      }
    }
  `)

  const fields: any = query.wpComponent

  return (
    <StyledHeader isSticky={isSticky}>
      <Container className="container">
        <Mobile
          fields={fields}
          setIsScrollable={setIsScrollable}
          isSticky={isSticky}
        />
      </Container>
      <Desktop fields={fields} isSticky={isSticky} />
    </StyledHeader>
  )
}

export default Header
